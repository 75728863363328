import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Column from '../components/Column';
import Markdown from '../components/Markdown';
import Document from '../components/Document';
import Banner from '../components/Banner';
import Section from '../components/Section';
import LinkButton from '../components/LinkButton';

const CompaniesPage = ({
  data: {
    datoCmsCompaniesPage: { header, punchline, bannerImage, content, seo },
  },
}) => {
  return (
    <Document seo={seo}>
      <Banner bgImage={bannerImage} header={header} punchline={punchline} />
      <Section>
        <Column>
          <Markdown source={content} />
          <LinkButton dark label="Kontakta oss" to="/kontakt" />
        </Column>
      </Section>
    </Document>
  );
};

export const query = graphql`
  {
    datoCmsCompaniesPage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      header
      punchline
      bannerImage {
        url
      }
      content
    }
  }
`;

CompaniesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CompaniesPage;
